<template>
    <div class="crear-grupo" :class="show ? 'activo':'inactivo'">
        <div class="row header mx-0 py-2">
            <div class="col-12 d-flex ">
                <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="$emit('close')"/>
                <p class="f-17 f-500 my-auto">Nuevo grupo</p>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col-12 text-center">
                <slim-cropper ref="cropGrupo" :options="slimOptions" class="border cr-pointer mx-auto" style="height:164px;width:164px;background-color:#f1f1f1; color: #868686;border-radius:50%;" />
            </div>
        </div>
        <div class="row mx-0 mt-5 justify-content-center">
            <div class="col-10">
                <p class="f-12 pl-2 label-inputs">Nombre del grupo</p>
                <el-input v-model="form.nombre" placeholder="Nombre grupo" maxlength="60" size="small" />
            </div>
            <div class="col-10">
                <p class="f-12 pl-2 label-inputs mt-2">Descripción</p>
                <el-input v-model="form.descripcion" type="textarea" maxlength="300" placeholder="Descripción..." :rows="3" />
            </div>
        </div>
        <div class="row mx-0 mt-4 justify-content-center">
            <div class="col-10">
                <button class="btn btn-general f-14 f-300 w-100" @click="crearGrupo">
                    Continuar
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import { mapMutations } from 'vuex'
export default {
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen del grupo',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            form:{
                nombre:'',
                descripcion:''
            },
        }
    },
    methods:{
        ...mapMutations({
            setChatsGrupos: 'chatsGrupos/setChatsGrupos'
        }),
        async crearGrupo(){
            try {
                if(this.form.nombre === null || this.form.nombre === ''){
                    this.notificacion('Advertencia','El nombre del grupo es requerido','warning')
                    return
                }

                this.form.logo = this.$refs.cropGrupo.get_image()
                const {data} = await Chats.crearGrupo(this.form)
                this.notificacion('Exito','Grupo agregado correctamente','success')
                this.getChatsGrupos()
                this.$emit('close')

            } catch (e){
                this.errorCatch(e)
            }
        },
        async getChatsGrupos(){
            try {
                
                const {data} = await Chats.getChatsGrupos()
                this.setChatsGrupos(data.data.grupos)
               
            } catch (error){
                this.errorCatch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .crear-grupo, .agregar-residentes-grupo, .listado-residentes-seleccionado, .enviar-mensaje-nuevo{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 378px;
        left: -393.77px;
        display: none;
        &.activo{
            transform: translateX(395px);
            display: block !important;
            animation-duration: 0.35s;
            // animation-name: slidein;
            z-index: 2;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-img-grupo{
            border-radius: 50%;
            border: 2px solid #F5F5F5;
            position:relative;
            .bg-hover{
                border-radius: 50%;
                background: #000;
                opacity: 0;
                position: absolute;
                transition: .35s;
            }
            .img-grupo{
                border: none !important;
            }
            &:hover{
                .bg-hover{
                    opacity: 0.7;
                    transition: .45s;

                }
            }
        }
        .contenedor-residentes{
            height: calc(100vh - 206px);
        }
        .contenedor-nuevo-mensaje{
            height: calc(100vh - 160px);
        }
        .contenedor-residentes-admins{
            height: calc(100vh - 135px);
        }
        .mas-opciones.opt{
            position: relative;
            .cantidad{
                position: absolute;
                color: white;
                background: #FF4D59;
                border-radius: 7px;
                font-size: 8px;
                top: -6px;
                right: -10px;
                font-weight: 500;
            }
        }
    }
</style>